import React from 'react'
import {Link} from 'gatsby'
import imgLogo from '../images/logo1.svg'


export default function SiteHeader({}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}
    >

      <div>
        <Link to='/'>
          <img className='logo-header' src={imgLogo} />
        </Link>
      </div>

      <HeaderLink text='DATA' route='/data' />
      <HeaderLink text='BELLWETHERS' route='/bellwethers' />
      <HeaderLink text='COUNTER-ARGUMENTS' route='/counterarguments' />
      <HeaderLink text='FACTS' route='/facts' />

    </div>
  )
}


function HeaderLink({text, route}) {
  return (
    <div className='header-item'>
      <Link className='header-link' to={route}>
        {text}
      </Link>
    </div>
  )
}
