import React from 'react'
import {Link} from 'gatsby'
import imgLogo from '../images/logo1.svg'


export default function FrontNav({}) {


  return (
    <div
      style={{
        margin: '3rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          flex: '1 1 auto',
        }}
      />

      <div
        style={{
          flex: '1 1 auto',
          maxWidth: '60vw',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            marginBottom: '4rem',
          }}
        >
          <img src={imgLogo} className='logo-front' />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'strecth',
          }}
        >

          <div
            style={{
              flex: '1 1 auto',
              margin: '1rem',
              padding: '1rem',
              backgroundColor: '#eff2f6',
              border: '1px solid #dadada',
            }}
          >
            <div style={{whiteSpace:'nowrap'}}>
              <div
                style={{
                  display: 'inline-block',
                  height: '1.15rem',
                  width: '1.15rem',
                  backgroundColor: '#006bfe',
                  marginRight: '1px',
                }}
              />
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '1.5rem',
                  fontFamily: 'texgyrebonum',
                  letterSpacing: '-2px',
                }}
              >
                Data
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                marginTop: '1rem',
              }}
            >

              <div
                style={{
                  backgroundColor: '#f5f8fa',
                  margin: '0.5rem',
                  padding: '1rem',
                  cursor: 'pointer',
                  fontFamily: 'sans-serif',
                  //border: '1px solid #000',
                  //boxShadow: '0px 0px 6px 2px #dadada',
                }}
              >
                <div
                  style={{
                    marginBottom: '1em',
                    fontWeight: 'bold',
                    fontSize: '1.1em',
                  }}
                >
                  Keshel Analysis
                </div>

                <div>
                  Browse the complete repository of Keshel data for all 50 states
                </div>
              </div>

              <div
                style={{
                  backgroundColor: '#f5f8fa',
                  margin: '0.5rem',
                  padding: '1rem',
                  cursor: 'pointer',
                  fontFamily: 'sans-serif',
                  //border: '1px solid #000',
                  //boxShadow: '0px 0px 6px 2px #dadada',
                }}
              >
                <div
                  style={{
                    marginBottom: '1em',
                    fontWeight: 'bold',
                    fontSize: '1.1em',
                  }}
                >
                  ENR
                </div>

                <div>
                  Archived election night reporting data from each state
                </div>
              </div>

              <div
                style={{
                  backgroundColor: '#f5f8fa',
                  margin: '0.5rem',
                  padding: '1rem',
                  cursor: 'pointer',
                  fontFamily: 'sans-serif',
                  //border: '1px solid #000',
                  //boxShadow: '0px 0px 6px 2px #dadada',
                }}
              >
                <div
                  style={{
                    marginBottom: '1em',
                    fontWeight: 'bold',
                    fontSize: '1.1em',
                  }}
                >
                  Fulton Ballot Images
                </div>

                <div>
                  Scanned images of absentee ballots from Fulton County, Georgia
                </div>
              </div>

            </div>
          </div>
        </div>



        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'stretch',
            marginTop: '2rem',
          }}
        >



          <div
            style={{
              flex: '1 1 10rem',
              margin: '1rem',
              padding: '1rem',
              backgroundColor: '#eff2f6',
              border: '1px solid #dadada',
              cursor: 'pointer',
            }}
          >
            <div style={{whiteSpace:'nowrap'}}>
              <div
                style={{
                  display: 'inline-block',
                  height: '1.15rem',
                  width: '1.15rem',
                  backgroundColor: '#d9004f',
                  marginRight: '1px',
                }}
              />
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '1.5rem',
                  fontFamily: 'texgyrebonum',
                  letterSpacing: '-2px',
                  cursor: 'pointer',
                }}
              >
                Bellwethers
              </div>
            </div>

            <div
              style={{
                margin: '1rem 1.5rem',
                cursor: 'pointer',
              }}
            >
              A summary of indicators that have a
              history of predictably aligning with election outcomes
            </div>
          </div>



          <div
            style={{
              flex: '1 1 10rem',
              margin: '1rem',
              padding: '1rem',
              backgroundColor: '#eff2f6',
              border: '1px solid #dadada',
              cursor: 'pointer',
            }}
          >
            <div style={{whiteSpace:'nowrap'}}>
              <div
                style={{
                  display: 'inline-block',
                  height: '1.15rem',
                  width: '1.15rem',
                  backgroundColor: '#62d0b5',
                  marginRight: '1px',
                }}
              />
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '1.5rem',
                  fontFamily: 'texgyrebonum',
                  letterSpacing: '-2px',
                  cursor: 'pointer',
                }}
              >
                Counter-arguments
              </div>
            </div>

            <div
              style={{
                margin: '1rem 1.5rem',
                cursor: 'pointer',
              }}
            >
              A dissection of the logic behind
              both the criticisms and defenses
              of election integrity efforts
            </div>
          </div>



          <div
            style={{
              flex: '1 1 10rem',
              margin: '1rem',
              padding: '1rem',
              backgroundColor: '#eff2f6',
              border: '1px solid #dadada',
              cursor: 'pointer',
            }}
          >
            <div style={{whiteSpace:'nowrap'}}>
              <div
                style={{
                  display: 'inline-block',
                  height: '1.15rem',
                  width: '1.15rem',
                  backgroundColor: '#612ffe',
                  marginRight: '1px',
                }}
              />
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '1.5rem',
                  fontFamily: 'texgyrebonum',
                  letterSpacing: '-2px',
                  cursor: 'pointer',
                }}
              >
                Facts
              </div>
            </div>

            <div
              style={{
                margin: '1rem 1.5rem',
                cursor: 'pointer',
              }}
            >
              A collection of objective observations that can
              be considered indisputable
            </div>
          </div>



        </div>
      </div>

      <div
        style={{
          flex: '1 1 auto',
        }}
      />

    </div>
  )
}

