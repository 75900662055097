import React from 'react'


export default function LayoutContainer({location, children, navigate}) {


  return (
    <div
      style={{
        padding: '0px 0px 0px 0px',
      }}
    >

      {children}

    </div>
  )
}
