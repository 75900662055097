// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bellwethers-js": () => import("./../../../src/pages/bellwethers.js" /* webpackChunkName: "component---src-pages-bellwethers-js" */),
  "component---src-pages-counterarguments-index-js": () => import("./../../../src/pages/counterarguments/index.js" /* webpackChunkName: "component---src-pages-counterarguments-index-js" */),
  "component---src-pages-counterarguments-maricopa-audit-js": () => import("./../../../src/pages/counterarguments/maricopa-audit.js" /* webpackChunkName: "component---src-pages-counterarguments-maricopa-audit-js" */),
  "component---src-pages-counterarguments-trump-az-interference-js": () => import("./../../../src/pages/counterarguments/trump-az-interference.js" /* webpackChunkName: "component---src-pages-counterarguments-trump-az-interference-js" */),
  "component---src-pages-counterarguments-trump-ga-interference-js": () => import("./../../../src/pages/counterarguments/trump-ga-interference.js" /* webpackChunkName: "component---src-pages-counterarguments-trump-ga-interference-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-facts-js": () => import("./../../../src/pages/facts.js" /* webpackChunkName: "component---src-pages-facts-js" */),
  "component---src-pages-fulton-js": () => import("./../../../src/pages/fulton.js" /* webpackChunkName: "component---src-pages-fulton-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keshel-index-js": () => import("./../../../src/pages/keshel/index.js" /* webpackChunkName: "component---src-pages-keshel-index-js" */)
}

