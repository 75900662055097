import React from 'react'
import {Link} from 'gatsby'
import FrontHeader from '../components/FrontHeader'
import FrontNav from '../components/FrontNav'


export default function IndexPage({}) {
  return (
    <div>

      <FrontNav />


    </div>
  )
}
