import React from 'react'
import {Link} from 'gatsby'


export default function NotFoundPage({}) {
  return (
    <div>
      <h1>Page not found</h1>

      <p>
        <Link to='/'>Return to the main page</Link>.
      </p>
    </div>
  )
}
